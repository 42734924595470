function UtilContent(content, key, debug)
{
    if (content === undefined)
    {
        if (debug === true)
            return key;
        else
            return null;
    }
    else
        return content;
}

function UtilTranslate(translations, key, debug)
{
    let translation = translations[key];

    if (debug === true)
    {
        if (translation !== undefined)
            return translation;
        else
            return key;
    }

    if (translation !== undefined)
        return translation;
    else
        return '';
}

function UtilRoute(language, routes_urls, key, slug)
{
    if (language !== null)
    {
        switch (language)
        {
            case 'nl-nl':
            case 'NL-nl':   
            case 'nl-NL':   language = 'nl-NL';
                            break;

            case 'en-gb':
            case 'EN-gb':   
            case 'en-GB':   language = 'en-GB';
                            break;
            
            case 'bs-ba':
            case 'BS-ba':   
            case 'bs-BA':   language = 'bs-BA';
                            break;

            default:        language = 'nl-NL';
                            break;
        }

        let url = '/'+language+'/'+routes_urls[language][key];

        if (slug !== null)
            url += '/'+slug;
        
        return url;
    }
    else
        return null;
}

export { UtilContent, UtilTranslate, UtilRoute }